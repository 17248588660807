<template>
  <v-app-bar app color="white">
    <div class="d-flex align-center">
      <h1 id="lbl-system-name">Cargos</h1>
    </div>

    <v-spacer></v-spacer>

    <v-btn
      id="link-progress-confirmation"
      text
      class="menu-btn"
      @click="routeProgressSummary"
      :disabled="!canProgressConfirmation"
      >進捗確認▼</v-btn
    >

    <v-btn
      id="link-shipp-achievement"
      text
      class="menu-btn"
      @click="routeShipmentRecordExtraction"
      :disabled="!canShippAchievement"
      >出荷実績抽出▼</v-btn
    >

    <v-menu bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="link-master-manage "
          text
          class="menu-btn"
          :disabled="!canMasterManage"
          v-bind="attrs"
          v-on="on"
        >
          マスタ管理▼</v-btn
        >
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title @click="routeMasterManageList">搬入先グループマスタ</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer></v-spacer>

    <label id="lbl-user-name">{{ userFullName }}</label>

    <v-btn text id="btn-logout" class="menu-btn" @click="logout">
      <img src="../assets/img/logout_menu_icon.png" />ログアウト
    </v-btn>
  </v-app-bar>
</template>

<script>
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { appConfig } from "../assets/scripts/js/AppConfig";
export default {
  name: "AppBar",
  data: () => ({
    familyName: "",
    givenName: "",
    userId: "",
    roles: "",
    autoRefreshEventIds: null,
  }),
  methods: {
    init() {
      console.log("init");
      Promise.all([])
        .then(() => {
          // 自動更新を設定します。
          this.autoRefreshEventIds = setInterval(this.getCredentials, 1000 * 60 * 59);
        })
        .catch((ex) => {
          console.error("init error", ex);
        })
        .finally(() => {});
    },
    routeProgressSummary() {
      this.$router.push("progress_summary");
    },
    routeShipmentRecordExtraction() {
      this.$router.push("shipment_record_extraction");
    },
    routeMasterManageList() {
      this.$router.push("master_manage_list");
    },
    /**
     * ログアウトボタンのバインド変数を設定します。
     */
    logout() {
      this.$httpClient.logout();
      console.info("logout");
      var url = "/";
      // 警告灯フラグが存在する場合、ログイン画面のパラメータに追加
      if (sessionStorage.getItem("pt") == "1") {
        url = url + "?pt=1";
      }
      this.$router.push(url);
    },
    getCredentials() {
      this.$httpClient.getApiGatewayCredentials().then(() => {});
    },
  },
  computed: {
    /**
     * ユーザー名を氏名の形式で返します。
     */
    userFullName() {
      return this.familyName + this.givenName;
    },
    /**
     * 進捗状況サマリーを使用できるロールを持つかを確認します。
     */
    canProgressConfirmation() {
      const role = this.roles.split(",")[0];
      let canScreen = screenAuth.canScreenAuth(role, appConfig.SCREEN_ID.P_MNG_001);

      return canScreen;
    },
    /**
     * 出荷実績抽出を使用できるロールを持つかを確認します。
     */
    canShippAchievement() {
      const role = this.roles.split(",")[0];
      let canScreen = screenAuth.canScreenAuth(role, appConfig.SCREEN_ID.P_MNG_003);

      return canScreen;
    },
    /**
     * マスタ管理を使用できるロールを持つかを確認します。
     */
    canMasterManage() {
      const role = this.roles.split(",")[0];
      let canScreen = screenAuth.canScreenAuth(role, appConfig.SCREEN_ID.P_MNG_003);

      return canScreen;
    },
  },
  mounted() {
    this.init();
    this.userId = sessionStorage.getItem("username");
    this.roles = sessionStorage.getItem("role");
    this.$httpClient
      .getUserAttribute(this.userId)
      .then((result) => {
        this.familyName = result.get("family_name");
        this.givenName = result.get("given_name");
      })
      .catch((error) => {
        console.error("mounted error", error);
      });
  },
  destroyed() {
    // 自動更新イベントを開放します。
    if (this.autoRefreshEventIds) {
      clearInterval(this.autoRefreshEventIds);
    }
  },
};
</script>

<style lang="scss" scoped>
$menu-fontsize: large;
#company-logo {
  width: 55px;
  height: 55px;
}
#lbl-system-name {
  font-family: Meiryo;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  font-size: $menu-fontsize !important;
  font-weight: bold;
}
#lbl-user-name {
  font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
header.v-app-bar.v-app-bar--fixed {
  z-index: 100;
}
</style>
